<template>
  <div style="height: 100%;font-size: 0.9rem;">
    <div
      v-if="existingUser.tmp"
      style="border: solid 2px red;padding: 10px;margin-top:10px;margin-bottom: 20px;"
    >
      <div style="color:red;text-align:center;">
        This is a reminder that you should update your temporary password.
      </div>
    </div>
    <div class="text-left primary-colour">
      <span class="d-block mt-3">
        If you wish to update your existing password, please complete the details in the form below.
      </span>
    </div>
    <validation-observer>
      <form style="width:100%;" autocomplete="off">
        <div class="md-form form-sm" style="margin-top:20px;">
          <validation-provider
            mode="lazy"
            name="Current Password"
            rules="required"
          >
            <i class="fas fa-lock prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
            <mdb-input
              v-model="existingUser.pwd"
              :type="pwdVisibility"
              auto-complete="ex-pwd"
              label="Current Password"
              style="margin-left:33px;"
              size="sm"
            />
            <div style="position:absolute;right:0;top:9px;">
              <a v-if="pwdVisibility == 'password'" @click="showPassword()"><i class="fas fa-eye grey-text" /></a>
              <a v-if="pwdVisibility == 'text'" @click="showPassword()"><i class="fas fa-eye-slash grey-text" /></a>
            </div>
          </validation-provider>
        </div>
        <div class="md-form form-sm" style="margin-top:20px;">
          <validation-provider
            mode="lazy"
            name="New Password"
            rules="required"
          >
            <i class="fas fa-lock prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
            <mdb-input
              v-model="existingUser.nwp"
              :type="newVisibility"
              auto-complete="pw-new"
              label="New Password"
              style="margin-left:33px;"
              size="sm"
            />
            <div style="position:absolute;right:0;top:9px;">
              <a v-if="newVisibility == 'password'" @click="newPassword()"><i class="fas fa-eye grey-text" /></a>
              <a v-if="newVisibility == 'text'" @click="newPassword()"><i class="fas fa-eye-slash grey-text" /></a>
            </div>
          </validation-provider>
        </div>
        <div class="md-form form-sm" style="margin-top:20px;margin-bottom: 35px;">
          <validation-provider
            mode="lazy"
            name="Confirm New Password"
            rules="required"
          >
            <i class="fas fa-lock prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
            <mdb-input
              v-model="existingUser.con"
              :type="conVisibility"
              auto-complete="pw-con"
              label="Confirm New Password"
              style="margin-left:33px;"
              size="sm"
            />
            <div style="position:absolute;right:0;top:9px;">
              <a v-if="conVisibility == 'password'" @click="confirmPassword()"><i class="fas fa-eye grey-text" /></a>
              <a v-if="conVisibility == 'text'" @click="confirmPassword()"><i class="fas fa-eye-slash grey-text" /></a>
            </div>
          </validation-provider>
        </div>
        <div
          class="d-flex align-items-center mt-3 cp"
          style="transform: translateX(-20px);font-size: 0.9rem;"
          @click="toggleAgree()"
        >
          <mdb-input
            v-model="agree"
            type="checkbox"
            auto-complete="ex-agr"
            size="sm"
          />
          <div class="white-text mb-2">
            Tick to confirm you are changing your password.
          </div>
        </div>
      </form>
    </validation-observer>
    <div style="float: left;margin-top:20px;padding-bottom: 30px;" class="mr-4 mr-md-5">
      <mdb-btn
        v-if="updated === false && error === false"
        v-show="!buttonClick"
        :disabled="agree === false"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        @click="update()"
      >
        UPDATE
      </mdb-btn>
      <mdb-btn
        v-if="updated === false && error === false"
        v-show="buttonClick"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
      <mdb-btn
        v-if="updated"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
      >
        <mdb-icon
          icon="fa fa-check"
          style="font-size:1rem;" 
        />
      </mdb-btn>
      <mdb-btn
        v-if="error"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
      >
        <mdb-icon
          icon="fa fa-times"
          style="font-size:1rem;" 
        />
      </mdb-btn>
    </div>
    <div
      v-if="error"
      class="errMsg"
    >
      SORRY, THERE WAS AN ERROR RESETTING YOUR PASSWORD, PLEASE TRY AGAIN!
    </div>
    <div
      v-if="updated"
      class="errMsg"
    >
      PASSWORD SUCCESSFULLY UPDATED!
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userService from '@/api-services/user.service'

export default {
  name: 'MyPassword',
  components: {
  },
  props: {
  },
  data () {
    return {
      existingUser: {
        fnm: '',
        lnm: '',
        une: '',
        day: '',
        mth: '',
        yer: '',
        adr: '',
        pce: '',
        tel: '',
        pwd: '',
        nwp: '',
        con: ''
      },
      buttonClick: false,
      agree: false,
      pwdVisibility: 'password',
      newVisibility: 'password',
      conVisibility: 'password',
      updated: false,
      error: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  created () {
  },
  mounted () {
    this.getMyDetails()
  },
  methods: {
    showPassword () {
      this.pwdVisibility = this.pwdVisibility === 'password' ? 'text' : 'password'
    },
    newPassword () {
      this.newVisibility = this.newVisibility === 'password' ? 'text' : 'password'
    },
    confirmPassword () {
      this.conVisibility = this.conVisibility === 'password' ? 'text' : 'password'
    },
    toggleAgree () {
      if (this.agree === true) {
        this.agree = false
      } else {
        this.agree = true
      }
    },
    update () {
      this.buttonClick = true
      userService.editMyDetails(this.userData.access_token, true, this.existingUser).then((res) => {
        this.existingUser = res.data
        this.agree = false
        this.buttonClick = false
        this.updated = true
        this.pwdVisibility = 'password'
        this.newVisibility = 'password'
        this.conVisibility = 'password'
        this.existingUser.pwd = ''
        this.existingUser.nwp = ''
        this.existingUser.con = ''
        setTimeout(() => { this.updated = false }, 5000)
      }).catch((err) => {
        this.buttonClick = false
        this.pwdVisibility = 'password'
        this.newVisibility = 'password'
        this.conVisibility = 'password'
        this.existingUser.pwd = ''
        this.existingUser.nwp = ''
        this.existingUser.con = ''
        this.agree = false
        this.error = true
        setTimeout(() => { this.error = false }, 5000)
        console.log(err)
      })
    },
    getMyDetails () {
      userService.getMyDetails(this.userData.access_token).then((res) => {
        this.existingUser = res.data
      }).catch((err) => {
        this.errorAlert({ err, msg: 'Sorry an error has occurred!' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .errMsg {
    color: white;
    margin-top: 82px;
    margin-left: -155px !important;
    float: left;
  }
  @media (max-width: 576px) {
    .tick {
      margin-top: -30px !important;
    }
    .bfr {
      float:right !important;
    }
    .errMsg {
      color: white;
      margin-top: -10px;
      margin-left: 0px !important;
      float: left;
    }
  }
</style>
